<template>
  <div class="container">
    <TitleHeader>
      {{ auuidParam ? getApplicationFullName + " " : "" }}File Upload
    </TitleHeader>
    <div class="card">
      <div class="card-body">
        <Form
            ref="uploadForm"
            :validation-schema="schema"
            class="-constrained"
            @submit="uploadFiles"
        >
          <TextInput
              :value="auuidParam"
              label="Application UUID"
              name="uuid"
              placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              type="text"
              @error-update="handleUuidError"
          />
          <div class="position-relative">
            <div
                v-if="updating"
                class="overlay-div d-flex align-items-center justify-content-center"
            >
              <LoadingSpinnerIcon
                  :width="50"
                  :height="100"
              />
            </div>
            <SelectInput
                :options="this.uploadTypeComp"
                :value="selectedOption"
                label="File type"
                name="upload_type"
                @input="handleChange"
                @error-update="handleTypeError"
            />
          </div>
          <TextInput
              v-if="
              uploadTypeSelection === 'lor' ||
                uploadTypeSelection === 'lor_addendum' ||
                uploadTypeSelection === 'additional_info' ||
                uploadTypeSelection === 'loa_rejection' ||
                uploadTypeSelection === 'customer_chasing' ||
                uploadTypeSelection === 'op_sent_lor' ||
                uploadTypeSelection === 'op_cannot_find_policy' ||
                uploadTypeSelection === 'op_policy_doesnt_exist' ||
                uploadTypeSelection === 'wet_signature_loa' ||
                uploadTypeSelection === 'complaint' ||
                uploadTypeSelection === 'other'
            "
              :value="tpuuidParam"
              label="Traced Pension UUID"
              name="traced_pension_uuid"
              placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              type="text"
              @error-update="handlePensionUuid"
          />
          <div v-if="copy === 'false'" :class="[obfuscated]">
            <UploadComponent
                ref="uploadComponent"
                @file-deleted="clearFileArray"
                @file-uploaded="updateFiles($event)"
            ></UploadComponent>
            <button
                ref="submitUploadFormBtn"
                :disabled="files.length === 0 || uploading"
                class="fabric-btn fabric-btn-submit"
            >
              Upload
            </button>
            <h4 v-if="showComplete" class="text-color-green mt-5">
              Upload Complete!
            </h4>
          </div>
          <div v-else>
            <CopyComponent :attachments="this.getAttachments"/>
            <button :disabled="uploading" class="fabric-btn fabric-btn-submit">
              Upload email attachment
            </button>
            <h4 v-if="showComplete" class="text-color-green mt-5">
              Upload Complete!
            </h4>
          </div>
        </Form>
      </div>
    </div>
    <PasswordForm ref="passwordFormWrapper" @sendPassword="addPassword"/>
  </div>
</template>
<script>
import * as Yup from "yup";
import UploadComponent from "@/components/misc/UploadComponent";
import CopyComponent from "@/components/misc/CopyComponent";
import {mapActions, mapGetters, mapMutations} from "vuex";
import PasswordForm from "@/components/form/PasswordForm";
import LoadingSpinnerIcon from "@/components/loader/LoadingSpinnerIcon.vue";

export default {
  name: "FileUpload",
  setup() {
    const schema = Yup.object().shape({
      uuid: Yup.string()
          .required()
          .matches(
              /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/,
              {
                message: "Must be a valid UUID",
                excludeEmptyString: true
              }
          ),
      upload_type: Yup.string().required("required"),
      // traced_pension_uuid: Yup.string()
      //     .required()
      //     .matches(
      //         /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/,
      //         {
      //           message: "Must be a valid UUID",
      //           excludeEmptyString: true
      //         }
      //     )
    });
    return {
      schema
    };
  },
  data: () => ({
    files: [],
    uploading: false,
    updating: false,
    uuidAdded: true,
    uploadTypeAdded: true,
    uploadOriginAdded: true,
    pensionUuidAdded: true,
    showComplete: false,
    uploadTypeSelection: null,
    selectedOption: null,
    auuidParam: null,
    tpuuidParam: null,
    password: null,
    copy: "false",
    uploadType: []
  }),
  components: {
    LoadingSpinnerIcon,
    PasswordForm,
    UploadComponent,
    CopyComponent
  },
  computed: {
    ...mapGetters([
      "getApplication",
      "getApplicationUuid",
      "getAttachments",
      "getApplicationFullName"
    ]),
    // for PCS applications remove ability to upload lors
    uploadTypeComp() {
      switch (this.getApplication?.source) {
          // return this.uploadType.filter(i=>i.id !== 'lor' && i.id !== 'lor_addendum');
        case 'pcs':
        case 'pds':
        case 'fc':
          return this.uploadType;
      }
      return [];
    },
    obfuscated() {
      switch (this.uploadTypeSelection) {
        case "lor":
          return {
            "-obfuscated":
                this.uuidAdded ||
                this.uploadTypeAdded ||
                this.pensionUuidAdded
          };
        case "lor_addendum":
        case "additional_info":
        case "loa_rejection":
        case "customer_chasing":
        case "op_sent_lor":
        case "op_cannot_find_policy":
        case "op_policy_doesnt_exist":
        case "wet_signature_loa":
        case "complaint":
        case "other":
          return {
            "-obfuscated":
                this.uuidAdded || this.uploadTypeAdded || this.pensionUuidAdded
          };
        default:
          return {
            "-obfuscated": this.uuidAdded || this.uploadTypeAdded
          };
      }
    }
  },
  methods: {
    ...mapMutations(["setLoading", "setNotification"]),
    ...mapActions(["uploadFile", "getApplicationsFocus", "copyFileToLocation", "getUploadTypes", "decryptFile"]),
    addPassword({password}) {
      this.password = password;
      this.$refs.submitUploadFormBtn.click();
    },
    async handleChange(e) {
      switch (e.target.id) {
        case "upload_type":
          this.uploadTypeSelection = e.target.value;
          break;
      }
    },
    handleUuidError(errMsg) {
      this.uuidAdded = !errMsg;
    },
    handlePensionUuid(errMsg) {
      this.pensionUuidAdded = !errMsg;
    },
    handleTypeError(errMsg) {
      this.uploadTypeAdded = !errMsg;
    },
    clearFileArray() {
      this.files = [];
      this.uploading = false;
    },
    updateFiles(file) {
      this.files.push(file);
    },
    async uploadFiles(params) {
      if (this.uploading) return;

      if (
          this.password === null &&
          this.uploadTypeSelection === "lor" &&
          !confirm(
              'Please confirm you intend to upload a new LOR and not combine an LOR? \r\n\nTo combine an LOR please select the "LOR combine" option from the "File type" dropdown.'
          )
      )
        return;

      if (this.copy === "true") {
        this.copyFile(params);
      } else {
        this.basicUpload(params);
      }
    },
    async copyFile(params) {
      this.uploading = true;
      this.setLoading({
        bool: true,
        message: "Uploading..."
      });
      params.attachments = this.getAttachments;

      const response = await this.copyFileToLocation(params).catch(err => {
        this.uploading = false;
        this.setLoading({
          bool: false
        });
        this.setNotification({
          show: true,
          duration: 5000,
          type: "error",
          message: "Upload Error!",
          content: this.formulateErrorString(err)
        });
      });

      if (response) {
        this.uploading = false;
        this.setLoading({
          bool: false
        });
        this.setNotification({
          show: true,
          type: "success",
          message: "Upload Complete!"
        });
      }
    },
    async basicUpload(params) {
      this.uploading = true;
      this.setLoading({
        bool: true,
        message: "Uploading..."
      });

      const formData = new FormData();
      const uuid = params.uuid;
      const traced_pension_uuid = params.traced_pension_uuid;

      this.files.forEach(f => {
        formData.append("file[]", f);
        formData.append("ext", f.type.split("/")[1]);
      });

      formData.append("format", "html_input_file");
      formData.append("type", params.upload_type);
      formData.append("delivery_type", "email");

      if (traced_pension_uuid) {
        formData.append("traced_pension_uuid", traced_pension_uuid);
      }

      if (this.password) {
        formData.append("password", this.password);
      }

      const response = await this.uploadFile({
        formData,
        uuid
      }).catch(err => {
        this.uploading = false;
        this.password = null;

        this.setLoading({
          bool: false
        });

        if (err.response.data.type === 'App\\Exceptions\\PasswordProtected') {
          this.$refs.passwordFormWrapper.$refs.passwordForm.show = true;
          return;
        }

        this.setNotification({
          show: true,
          duration: 5000,
          type: "error",
          message: "Upload Error!",
          content: this.formulateErrorString(err)
        });
      });

      if (response && response.status === 200) {
        this.$refs.uploadComponent.clearFileArray();
        this.clearFileArray();
        this.uploading = false;
        this.setLoading({
          bool: false
        });
        this.setNotification({
          show: true,
          type: "success",
          message: "Upload Complete!"
        });
      }
    },
    prefillFields() {
      if (!window.location.search) return;

      this.selectedOption = "lor";
      this.uploadTypeSelection = this.selectedOption;
      this.auuidParam = this.$route.query.auuid; //valueArray[0];
      this.tpuuidParam = this.$route.query.tpuuid; //valueArray[1];

      if (this.$route.query.copy) {
        this.copy = this.$route.query.copy; //valueArray[1];
      }

      this.uuidAdded = false;
      this.uploadTypeAdded = false;
      this.pensionUuidAdded = false;
    }
  },
  async beforeMount() {
    this.prefillFields();
    if (this.auuidParam) {
      let uuid = this.auuidParam;
      await this.getApplicationsFocus(uuid);
    }

    let uuid = this.tpuuidParam;
    this.updating = true;
    await this.getUploadTypes(uuid).then((response) => {
      this.updating = false;
      this.uploadType = response.data.upload_types;
      this.selectedOption = response.data.selected_option;
    }).catch(err => console.log(err));
  },
};
</script>
<style lang="scss" scoped></style>
