<template>
  <div class="file-upload">
    <div v-if="fileTypeError" class="file-upload-error" @click="handleClose">
      Please only upload the allowed file types.
    </div>
    <div v-if="fileSizeError" class="file-upload-error" @click="handleClose">
      Please only upload a file below 100MB.
    </div>
    <!-- <form> -->
    <label
      :class="['file-upload-drop-area', { '-dragging': dragging }]"
      for="files"
      @dragenter="dragging = true"
      @dragend="dragging = false"
      @dragleave="dragging = false"
      @dragover.prevent
      @drop.prevent="handleDrop($event)"
    >
      <p v-if="filename == null" class="h-mb-1 h-pointer-events-none">
        1 file | 100MB maximum file size | only&nbsp;.pdf, .png or .jpg files
      </p>

      <div
        v-if="filename == null"
        class="file-upload-icon h-pointer-events-none"
      >
        <svg
          id="Layer_1"
          version="1.1"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 384 512"
          style="enable-background:new 0 0 384 512;"
          xml:space="preserve"
          class="-upload-icon"
        >
          <path
            d="M224,136V0H24C10.7,0,0,10.7,0,24v464c0,13.3,10.7,24,24,24h336c13.3,0,24-10.7,24-24V160H248C234.8,160,224,149.2,224,136z
          M289.2,352H224v80c0,8.8-7.2,16-16,16h-32c-8.8,0-16-7.2-16-16v-80H94.8c-14.3,0-21.4-17.3-11.3-27.4L180,229
          c6.6-6.6,17.4-6.6,24,0l96.4,95.7C310.6,334.7,303.5,352,289.2,352z M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1
          C384,115.6,381.5,109.5,377,105z"
          />
        </svg>
      </div>
      <div v-else class="file-upload-success h-pointer-events-none">
        <icon
          :data="checkCircle"
          width="100"
          height="100"
          dir="up"
          color="#17bd2c"
        />
      </div>
    </label>
    <div
      v-if="filename"
      class="file-upload-filename h-mt-1"
      @click="clearFileArray"
    >
      <span>{{ filename }}</span>
      <icon :data="close" width="20" height="20" dir="up" color="#e60000" />
    </div>
    <input
      v-if="uploadReady"
      id="files"
      ref="files"
      type="file"
      capture="camera"
      accept="image/*, application/*"
      multiple
      @change="handleFilesUpload"
    />
    <!-- </form> -->
  </div>
</template>

<script>
import checkCircle from "@fa/solid/check-circle.svg";
import close from "@fa/solid/window-close.svg";

export default {
  props: {
    refId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    uploadReady: true,
    fileTypeError: false,
    fileSizeError: false,
    dragging: false,
    draggedFiles: false,
    filename: null,
    filesize: null,
    files: [],
    checkCircle,
    close
  }),
  computed: {
    fileStyle() {
      return {
        "-file-added": this.files.length > 0
      };
    }
  },
  methods: {
    handleClose() {
      this.fileTypeError = false;
      this.fileSizeError = false;
    },
    clearFileArray() {
      this.files = [];
      this.filename = null;
      this.filesize = null;
      this.uploadReady = false;
      this.$emit("file-deleted");
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    handleDrop(e) {
      this.updateFiles(e.dataTransfer.files);
    },
    handleFilesUpload() {
      this.updateFiles(this.$refs.files.files);
    },
    updateFiles(chosenFiles) {
      this.dragging = false;
      if (!chosenFiles) return;
      [...chosenFiles].some(f => {
        if (Math.floor(f.size / 1024) > 100000) {
          this.fileSizeError = true;
          this.draggedFiles = true;
          this.clearFileArray();
          return true;
        }
        if (Math.floor(f.size / 1024) < 100000) {
          this.draggedFiles = true;
          this.filename = f.name;
          this.filesize = f.size;
          this.$emit("file-uploaded", f);
        }
      });
    },
    handleTriggerUpload() {
      this.$refs.files.click();
    }
  }
};
</script>
<style lang="scss">
input[type="file"] {
  position: absolute;
  top: -9999px;
}
</style>
