<template>
  <div v-if="attachments?.length > 0">
    <div
      v-for="(item, index) of attachments"
      :key="index"
      class="file-upload-filename h-mt-1"
      @click="clearFileArray(index)"
    >
      <span class="text-left">{{ parseFilename(item.link) }}</span>
      <icon :data="close" width="20" height="20" dir="up" color="#e60000" />
    </div>
  </div>
  <div v-else>no files selected</div>
</template>
<script>
import close from "@fa/solid/window-close.svg";
import { mapMutations } from "vuex";

export default {
  name: "CopyComponent",
  props: {
    attachments: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    close
  }),
  methods: {
    ...mapMutations(["setAttachments"]),
    clearFileArray(index) {
      let temp = this.attachments;
      temp.splice(index);
      this.setAttachments(temp);
    },
    parseFilename(text) {
      let regx = new RegExp(/(?<=email_attachment\/)(.*)(?=\?X-Amz)+/g);
      let result = text.match(regx);

      return result[0];
    }
  },
  beforeMount() {
    console.log(this.attachments);
  }
};
</script>
<style lang="scss"></style>
