<template>
  <EditModal ref="passwordForm" class="-maximise-width -overflow-visible -vw50">
    <template #btn-text></template>
    <template #formTitle></template>
    <template #form>
      <h4 class="mb-3">
        <slot name="formTitle">Enter file password</slot>
      </h4>
      <Form
          :validation-schema="schemaPW"
          class="w-100 text-left"
          @submit="saveForm"
      >
        <TextInput
            label=""
            name="password"
            type="text"
        />
        <button class="fabric-btn fabric-btn-submit">Save</button>
      </Form>
    </template>
  </EditModal>
</template>
<script>
import * as Yup from "yup";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "PasswordForm",
  setup() {
    const schemaPW = Yup.object().shape({
      password: Yup.string().required("required"),
    });
    return {
      schemaPW,
    };
  },
  data: () => ({}),
  methods: {
    ...mapMutations(["setLoading", "setNotification"]),
    ...mapActions(["decryptFile"]),
    async saveForm({password}) {
      this.$emit('send-password', {password})
      this.$refs.passwordForm.show = false;
    },
  },
}
</script>
<style lang="scss"></style>